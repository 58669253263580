import React from 'react'
import { IconLight, IconDark } from './theme-swithcer.styles'
import { useColorMode } from 'contexts'
import { Container } from './theme-swithcer.styles'
import { IconButton, Paper } from '@mui/material'

interface IThemeSwitcherProps {
  size: number
  shadow: boolean
  sx?: any
}

export const ThemeSwitcher: React.FC<IThemeSwitcherProps> = ({ size, shadow, sx }) => {
  const { mode, toggleColorMode } = useColorMode()

  return (
    <>
      {shadow ? (
        <IconButton onClick={toggleColorMode} sx={sx}>
          <Paper elevation={1} sx={{ padding: 2, borderRadius: '100%' }}>
            <Container size={size}>{mode === 'light' ? <IconDark size={size} /> : <IconLight size={size} />}</Container>
          </Paper>
        </IconButton>
      ) : (
        <IconButton onClick={toggleColorMode} sx={sx}>
          {mode === 'light' ? <IconDark size={size} /> : <IconLight size={size} />}
        </IconButton>
      )}
    </>
  )
}
