import React from 'react'
import { Button as MUIButton, useTheme } from '@mui/material'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sx?: any
  children?: any
  variant?: 'contained' | 'gradient'
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  style,
  type,
  sx,
  disabled = false,
  variant = 'contained'
}) => {
  const theme = useTheme()
  const styles = sx || {}
  if (variant === 'gradient') {
    styles.background = `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.secondary.main});`
  }

  return (
    <MUIButton variant={'contained'} onClick={onClick} disabled={disabled} type={type} style={style} sx={styles}>
      {children}
    </MUIButton>
  )
}
