import Swal from 'sweetalert2'
import { Alert } from '../types/Alert.type'

export const Confirm = async ({
  title,
  icon,
  confirmButtonText = 'Sim',
  confirmButtonColor = '#dd6b55',
  denyButtonText = 'Não',
  denyButtonColor = '#3085d6'
}: Alert) => {
  return await Swal.fire({
    title,
    icon,
    showConfirmButton: true,
    confirmButtonText,
    confirmButtonColor,
    showDenyButton: true,
    denyButtonText,
    denyButtonColor
  })
}
