import React from 'react'
import { TextFieldProps } from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import { styled } from '@mui/material'

import { TextField } from './input.styles'
import { PatternFormat, NumericFormat } from 'react-number-format'

type InputTextProps = TextFieldProps & {
  label?: string
  id?: string
  error?: any
  originalData?: any
  isTime?: boolean
}

type InputProps = Partial<InputTextProps> & {
  name: string
  control: any
  register: any
  originalData?: any
}

const CustomTextField = React.forwardRef<HTMLInputElement, InputTextProps>(({ error, ...props }, ref) => {
  const { originalData, isTime } = props

  const precision = originalData?.precision
  delete props.originalData

  if (isTime) {
    return (
      <PatternFormat
        margin="normal"
        error={!!error}
        helperText={error?.message}
        InputLabelProps={{
          shrink: true
        }}
        inputRef={ref}
        format="##:##"
        patternChar="#"
        mask="-"
        allowEmptyFormatting
        // @ts-ignore
        customInput={TextField}
        label={props.label}
        name={props.name}
        className={props.className}
        required={props.required}
        // @ts-ignore
        value={props?.defaultValue || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    )
  }

  if (!!precision) {
    return (
      <NumericFormat
        margin="normal"
        error={!!error}
        helperText={error?.message}
        InputLabelProps={{
          shrink: true
        }}
        inputRef={ref}
        decimalScale={precision}
        // @ts-ignore
        customInput={TextField}
        label={props.label}
        name={props.name}
        className={props.className}
        required={props.required}
        // @ts-ignore
        value={props?.defaultValue || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    )
  }

  return (
    <TextField
      margin="normal"
      variant="outlined"
      error={!!error}
      helperText={error?.message}
      InputLabelProps={{
        shrink: true
      }}
      inputRef={ref}
      {...props}
    />
  )
})

const CustomInput = ({ name, control, register, ...props }: InputProps) => {
  if (!name || !control || !register) {
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <CustomTextField {...props} {...register(name)} />}
    />
  )
}

export const Input = styled(CustomInput)({
  boxSizing: 'border-box',

  '.MuiFormLabel-asterisk': {
    color: 'red'
  }
})
