import React from 'react'

import { TablePagination } from '@mui/material'

interface IPaginationProps {
  count: number
  page: number
  totalPages: number
  rowsPerPage: number
  setPage: Function
  setTotalPages: Function
  setRowsPerPage: Function
}

export const Pagination: React.FC<IPaginationProps> = ({ count, page, rowsPerPage, setPage, setRowsPerPage }) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}
