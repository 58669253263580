import { styled } from '@mui/material'

// * Icons
import { MdOutlineLogout } from 'react-icons/md'

export const IconLogout = styled(MdOutlineLogout)(({ theme }) => ({
  fill: theme.palette.text.primary,

  cursor: 'pointer'
}))
