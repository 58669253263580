//@ts-nocheck
import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Select as SelectMUI,
  FormHelperText,
  OutlinedInput,
  SelectChangeEvent,
  SelectProps,
  InputLabel,
  MenuItem,
  styled,
  FormControl
} from '@mui/material'
import { capitalize } from 'utils/capitalize'

type InputTextProps = SelectProps & {
  label?: string
  id?: string
  error?: any
}

type InputProps = Partial<InputTextProps> & {
  name: string
  control: any
  register: any
}

const CustomTextField = React.forwardRef<HTMLSelectElement, InputTextProps>(({ error, ...props }, ref) => {
  const [selectedItemId, setSelectedItemId] = React.useState(props.defaultValue?.id ?? '')

  if (props.defaultValue.options === undefined && props.defaultValue.id === undefined) {
    return <></>
  }
  const {
    defaultValue: { options, id: defaultValueId }
  } = props

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedItemId(event.target.value)
  }

  if (options.find(option => !option.nome)) {
    console.warn(`O campo < ${props.label} > não possui a propriedade <nome>`)
    return <></>
  }

  return (
    <FormControl sx={{ m: 1, width: `calc(50% - 16px)` }}>
      <InputLabel id={`${props.label}`} required={props.required} shrink>
        {capitalize(props.name)}
      </InputLabel>
      <SelectMUI
        {...props}
        displayEmpty
        fullWidth
        labelId={`${props.label}`}
        id={`${props.label}`}
        name={`${props.label}`}
        onBlur={props.onBlur}
        value={selectedItemId}
        label={props.label}
        onChange={handleChange}
        error={!!error}
        defaultValue={defaultValueId}
        input={<OutlinedInput notched label={props.label} ref={ref} />}
      >
        {options.map(option => (
          <MenuItem value={option.id} name={option.name} key={`${option.id}-option-select`}>
            {option.nome}
          </MenuItem>
        ))}
      </SelectMUI>
      {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  )
})

const CustomInput = ({ name, control, register, ...props }: InputProps) => {
  if (!name || !control || !register || !props?.options?.length < 0) {
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <CustomTextField {...props} {...field} {...register(name)} />}
    />
  )
}

export const Select = styled(CustomInput)({
  '.MuiFormLabel-asterisk': {
    color: 'red'
  }
})
