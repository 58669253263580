// @ts-nocheck
import React from 'react'
import api from 'services/api'
import { DataBaseContext } from 'contexts'
import type { Response, ResponseContent } from 'pages/crud/components/list/components/types/response.type'
import type { ColumnModified, TableModified } from 'types/table.type'
import { capitalize } from 'utils/capitalize'

export const useResponseApi = () => {
  const { allTables, hiddenColumns } = React.useContext(DataBaseContext)

  return {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getListData: React.useCallback(getListData(allTables), [allTables]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getRecycleListData: React.useCallback(getListData(allTables, true), [allTables]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getEditData: React.useCallback(getEditData(allTables), [allTables]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getCreateData: React.useCallback(getCreateData(allTables), [allTables]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setDataOnAPI: React.useCallback(setDataOnAPI(hiddenColumns), [hiddenColumns]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    createDataOnAPI: React.useCallback(createDataOnAPI(hiddenColumns), [hiddenColumns]),
    recoverItemFromDump: React.useCallback(recoverItemFromDump, []),
    deleteItem: React.useCallback(deleteItem, [])
  }
}

type getDataProps = {
  requestApi: string
  hasPaginate?: boolean
  page?: number
  rowsPerPage?: number
  filterText?: string | number
}

type getListDataProps = getDataProps & {
  table: TableModified
}

const getListData =
  (allTables, isRecycle = false) =>
  ({ requestApi, page, rowsPerPage, filterText, orderBy, table }: getListDataProps) => {
    return getApiData({ requestApi, page, rowsPerPage, filterText, orderBy, isRecycle }).then(response => {
      const externalRequestsIds = getExternalRequestsApisIds({ response, table })
      // @ts-ignore #ts(2802)
      const uniqueRequestsIds = [...new Set(externalRequestsIds?.map(JSON.stringify))].map(JSON.parse)

      return getMultipleApiDatas(uniqueRequestsIds, allTables, table).then(resultFromExternalTables => {
        response.content.forEach((line: ColumnModified): void => {
          if (table.hasMultiplePrimaryKey) {
            Object.entries(line.id).forEach(([key, value]) => {
              const alternativeColumnName = convertArrayToObject(
                Object.keys(resultFromExternalTables).map(externalKey => ({
                  [resultFromExternalTables[externalKey][value]?.alternativeColumnName]:
                    resultFromExternalTables[externalKey]
                }))
              )

              if (
                resultFromExternalTables?.[key as unknown as number] ||
                alternativeColumnName?.[key as unknown as number]
              ) {
                let resultColumnName = alternativeColumnName?.[key as unknown as number]
                  ? alternativeColumnName
                  : resultFromExternalTables
                // @ts-ignore #ts(7053) #ts(7054)
                line[resultColumnName[key][value].column] = resultColumnName[key][value].value
              }
            })
          } else {
            Object.keys(line).forEach((key: string) => {
              if (resultFromExternalTables?.[key as unknown as number]) {
                // @ts-ignore #ts(7053) #ts(7054)
                line[key] = resultFromExternalTables[key][line[key].id].nome
              }
            })
          }
        })

        return response
      })
    })
  }

const getEditData =
  allTables =>
  ({ requestApi, itemId, table }) => {
    let requestItemId = JSON.parse(itemId)

    if(typeof requestItemId === 'object'){
      requestItemId = '?' + convertObjectToQuerystring(requestItemId)
    }

    return getApiData({ requestApi: `${requestApi}/${requestItemId}`, hasPaginate: false }).then(response => {
      const externalRequestsIds = getExternalRequestsApisIds({ response: { content: [response] }, table })
      // @ts-ignore #ts(2802)
      const uniqueRequestsIds = [...new Set(externalRequestsIds?.map(JSON.stringify))].map(JSON.parse)

      return getMultipleApiDatasToSelect(uniqueRequestsIds, allTables, table).then(resultFromExternalTables => {
        Object.keys(resultFromExternalTables).forEach((columnName, resultIndex) => {
          if(typeof response?.id === 'object'){
            response[columnName] = {
              // Não sei se é o caminho correto, porém foi a unica forma que achei para popular tabelas relacionais.
              id: response.id[table.externalColumnsNames[resultIndex]],
              ...response[columnName],
              options: resultFromExternalTables[columnName].content
            }
          } else {
            response[columnName] = {
              ...response[columnName],
              options: resultFromExternalTables[columnName].content
            }

          }
        })
        return response
      })
    })
  }

const getCreateData =
  allTables =>
  ({ table }) => {
    let response = {}
    const externalRequestsIds = table.externalColumns.map(exColumn => {
      return [
        exColumn.relationValue.relationApi,
        exColumn.relationValue.relationColumn,
        exColumn.relationValue.name,
        null
      ]
    })

    return getMultipleApiDatasToSelect(externalRequestsIds, allTables, table).then(resultFromExternalTables => {
      Object.keys(resultFromExternalTables).forEach(columnName => {
        response[columnName] = {
          ...response[columnName],
          options: resultFromExternalTables[columnName].content,
          content: resultFromExternalTables[columnName].content
        }
      })

      return response
    })
  }

/**
 * Pega os dados da API que retorna os dados da tabela selecionada.
 * @param requestApi
 * @param page
 * @param rowsPerPage
 * @param filterText
 * @returns `Promise<Request>`
 */
const getApiData = ({
  requestApi,
  page,
  rowsPerPage,
  filterText,
  orderBy = false,
  isRecycle = false,
  hasPaginate = true
}: getDataProps): Promise<Response['data']> => {
  const pages = page || 0
  const size = rowsPerPage || 10
  const filter = `&filter=${filterText || 0}`
  const sort = `&sort=${orderBy}`
  const customRoute = isRecycle ? '/recycle-bin' : ''

  const querystring = hasPaginate ? `?page=${pages}&size=${size}${filterText ? filter : ''}${orderBy ? sort : ''}` : ''

  return api
    .get(`api/${requestApi}${customRoute}${querystring}`)
    .then(({ data }: Response) => data)
    .catch(console.error)
}

const getMultipleApiDatas = (listOfRequests, allTables = [], currentTable = {}) => {
  return Promise.allSettled(
    listOfRequests.map(([requestApi, requestColumnName, columnName, id]) => {
      // Caso a tabela seja abstrata, pega a informações dos filhos
      if (allTables?.[requestApi]?.isAbstract) {
        const childrenFormattedApi = allTables?.[requestApi].childrenTables.map(({ formattedApi, name }) => [
          formattedApi,
          allTables?.[requestApi].indexes[0].fields[0],
          columnName,
          id
        ])
        return getMultipleApiDatas(childrenFormattedApi)
          .then(data => data.flat())
          .catch(e => null)
      }
      return getApiData({ requestApi: `${requestApi}/${id}`, hasPaginate: false })
        .then(result => ({
          requestApi,
          requestColumnName,
          columnName,
          result
        }))
        .catch(e => {
          console.error(e)
          return {}
        })
    })
  )
    .then(results => results.map(result => result?.value).filter(value => value))
    .then(data => {
      let result = {}

      if (currentTable.hasMultiplePrimaryKey) {
        const alternativeExternalName = convertArrayToObject(
          currentTable.externalColumns.map(column => ({
            [column.relationValue.name]: column
          }))
        )

        data.forEach(({ columnName, requestColumnName, result: resultData }, index) => {
          const customColumnName = `${columnName}${capitalize(requestColumnName)}`
          if (!result[customColumnName]) {
            result[customColumnName] = {}
          }
          result[customColumnName][resultData.id] = {
            value: resultData.nome,
            column: columnName,
            alternativeColumnName: alternativeExternalName[columnName].name
          }
        })
      } else {
        data.forEach(({ columnName, requestColumnName, result: resultData }) => {
          if (!result[columnName]) {
            result[columnName] = {}
          }
          result[columnName][resultData[requestColumnName]] = resultData
        })
      }
      return result
    })
}

const getMultipleApiDatasToSelect = (listOfRequests, allTables = [], currentTable = {}) => {
  return Promise.allSettled(
    listOfRequests.map(([requestApi, requestColumnName, columnName, id]) => {
      // Caso a tabela seja abstrata, pega a informações dos filhos
      if (allTables?.[requestApi]?.isAbstract) {
        const childrenFormattedApi = allTables?.[requestApi].childrenTables.map(({ formattedApi, name }) => [
          formattedApi,
          allTables?.[requestApi].indexes[0].fields[0],
          columnName,
          id
        ])
        return getMultipleApiDatas(childrenFormattedApi)
          .then(data => data.flat())
          .catch(e => null)
      }
      return getApiData({ requestApi: `${requestApi}`, hasPaginate: false })
        .then(result => ({
          requestApi,
          requestColumnName,
          columnName,
          result
        }))
        .catch(e => {
          console.error(e)
          return {}
        })
    })
  )
    .then(results => results.map(result => result?.value || result?.content).filter(value => value))
    .then(data => {
      let result = []

      data.forEach(({ columnName, requestColumnName, result: resultData }) => {
        if (!result[columnName]) {
          result[columnName] = {}
        }
        if (currentTable.hasMultiplePrimaryKey) {
          if (resultData?.content) {
            const customColumnName = `${columnName}${capitalize(requestColumnName)}`
            result[columnName] = { content: resultData.content, column: customColumnName }
          } else {
            result[columnName] = { ...resultData, column: customColumnName }
          }
        } else {
          result[columnName] = {
            ...resultData
          }
        }
      })

      return result
    })
}

type externalRequestsApisIdsProps = {
  response: Response['data']
  table: TableModified
}

/**
 * Retorna lista de IDs necessários para fazer o request na api externa.
 * @returns [relationApi, relationColumn, relationName, value]
 */
const getExternalRequestsApisIds = ({ response, table }: externalRequestsApisIdsProps): number[] => {
  const { content } = response
  return content
    ?.map((line: ResponseContent): number[] => {
      if (table.isRelationalTable) {
        return Object.entries(line.id).map(([key, value]): (any[] | null)[] => {
          if (table.externalColumnsTotal > 0) {
            return table.externalColumns
              .map(externalColumnValue => {
                if (externalColumnValue.name === key) {
                  return [
                    externalColumnValue.relationValue.relationApi,
                    externalColumnValue.relationValue.relationColumn,
                    externalColumnValue.relationValue.name,
                    value
                  ]
                }
                return null
              })
              .filter(value => value)
          }
          return []
        })
      }
      return Object.entries(line).map(([key, value]): (any[] | null)[] => {
        if (table.externalColumnsTotal > 0) {
          return table.externalColumns
            .map(externalColumnValue => {
              if (externalColumnValue.relationValue.name === key) {
                return [
                  externalColumnValue.relationValue.relationApi,
                  externalColumnValue.relationValue.relationColumn,
                  externalColumnValue.relationValue.name,
                  value[externalColumnValue.relationValue.relationColumn]
                ]
              }
              return null
            })
            .filter(value => value)
        }
        return []
      })
    })
    .flatMap((value: any[]) => value)
    .flat()
}

/**
 * Faz o PUT dos dados na API
 */
const putDataOnApi = ({ putApi, body, injectBodyInUrl = false }) => {
  if (injectBodyInUrl) {
    return api.put(`api/${putApi}/${body}`)
  }

  return api.put(`api/${putApi}`, body)
}

/**
 * Trata e salva dados na API
 */
const setDataOnAPI =
  hiddenColumns =>
  ({ values, table, originalResponse }) => {
    // normaliza colunas externas para a forma que a API espera
    table.externalColumns.forEach(column => {
      if(table.isRelationalTable){
        if (Object.keys(values).includes(column.relationValue.name)) {
          values[column.name] = values[column.relationValue.name]
          delete values[column.relationValue.name]
        }
      } else {
        if (Object.keys(values).includes(column.relationValue.name)) {
          values[column.relationValue.name] = {
            id: values[column.relationValue.name],
            value: null
          }
          values.category_id = values[column.name]
        }
      }
    })

    let hiddenColumnsData = []

    if (Object.keys(originalResponse).length > 0) {
      hiddenColumnsData = hiddenColumns(table).map(({ name: columnName }) => {
        if (columnName === 'createdAt') {
          return { [columnName]: new Date(originalResponse[columnName]).toJSON() }
        }
        return { [columnName]: originalResponse[columnName] }
      })
    } else {
      hiddenColumnsData = hiddenColumns(table)
        .map(({ name: columnName }) => {
          if (columnName === 'createdAt') {
            return { [columnName]: new Date().toJSON() }
          }
          if (columnName === 'id') {
            return null
          }
          return { [columnName]: null }
        })
        .filter(value => value)
    }

    let dataToSend = {
      ...values,
      ...Object.assign({}, ...hiddenColumnsData)
    }

    let dataToSendId = ''

    if(table.isRelationalTable){
      dataToSendId = '?' + convertObjectToQuerystring(originalResponse.id)
      dataToSend = {
        id: dataToSend
      }

    } else {
      dataToSendId = dataToSend.id
    }

    return putDataOnApi({
      putApi: `${table.formattedApi}/${dataToSendId}`,
      body: dataToSend
    })
      .then(data => Promise.resolve(data))
      .catch(e =>
        Promise.reject({
          error: e,
          submitData: dataToSend
        })
      )
  }

/**
 * Faz o Post dos dados na API
 */
const postDataOnApi = ({ postApi, body }) => {
  return api.post(`api/${postApi}`, body)
}

/**
 * Trata e cria dados na API
 */

const createDataOnAPI =
  hiddenColumns =>
  ({ values, table, originalResponse }) => {
    // normaliza colunas externas para a forma que a API espera
    table.externalColumns.forEach(column => {
      if (table.isRelationalTable) {
        if (!values.id) {
          values['id'] = {}
        }

        if (Object.keys(values).includes(column.relationValue.name)) {
          values['id'][column.name] = values[column.relationValue.name]
        }
      } else {
        if (Object.keys(values).includes(column.relationValue.name)) {
          values[column.relationValue.name] = {
            id: values[column.relationValue.name],
            value: null
          }
        }
      }
    })

    let hiddenColumnsData = []

    if (Object.keys(originalResponse).length > 0) {
      hiddenColumnsData = hiddenColumns(table).map(({ name: columnName }) => {
        if (columnName === 'createdAt') {
          return { [columnName]: new Date(originalResponse[columnName]).toJSON() }
        }
        return { [columnName]: originalResponse[columnName] }
      })
    } else {
      hiddenColumnsData = hiddenColumns(table)
        .map(({ name: columnName }) => {
          if (columnName === 'createdAt') {
            return { [columnName]: new Date().toJSON() }
          }
          if (columnName === 'id') {
            return null
          }
          return { [columnName]: null }
        })
        .filter(value => value)
    }

    const dataToSend = {
      ...values,
      ...Object.assign({}, ...hiddenColumnsData)
    }

    return postDataOnApi({
      postApi: `${table.formattedApi}`,
      body: dataToSend
    })
      .then(data => Promise.resolve(data))
      .catch(e =>
        Promise.reject({
          error: e,
          submitData: dataToSend
        })
      )
  }

export const recoverItemFromDump = ({ table, id }) => {
  let itemId = JSON.parse(id)

  if(typeof itemId === 'object') {
    itemId = '?' + convertObjectToQuerystring(itemId)
  }

  return putDataOnApi({
    putApi: `${table.formattedApi}/restore`,
    body: itemId,
    injectBodyInUrl: true
  })
    .then(_ =>
      api
        .get(`api/${table.formattedApi}/recycle-bin`)
        .then(data => Promise.resolve(data))
        .catch(e => getCatchData(e, itemId, data))
    )
    .catch(e =>
      Promise.reject({
        error: e,
        submitData: itemId
      })
    )
}

const deleteDataOnApi = ({ deleteApi, body, injectBodyInUrl = false }) => {
  if (injectBodyInUrl) {
    return api.delete(`api/${deleteApi}/${body}`)
  }

  return api.delete(`api/${deleteApi}`, body)
}

export const deleteItem = ({ id, tableName, isDump = false }) => {
  let itemId = JSON.parse(id)

  if(typeof itemId === 'object') {
    itemId = '?' + convertObjectToQuerystring(itemId)
  }

  const deleteRoute = isDump ? `${tableName}/destroy` : tableName
  const getRoute = isDump ? `${tableName}/recycle-bin` : tableName

  return deleteDataOnApi({ deleteApi: deleteRoute, body: itemId, injectBodyInUrl: true })
    .then(_ =>
      api
        .get(`api/${getRoute}`)
        .then(data => Promise.resolve(data))
        .catch(e => getCatchData(e, itemId))
    )
    .catch(e =>
      api
        .get(`api/${getRoute}`)
        .then(data => getCatchData(e, itemId, data))
        .catch(e => getCatchData(e, itemId))
    )
}

const getCatchData = (error, id, data) => {
  return Promise.reject({
    error,
    submitData: id,
    data
  })
}

const convertArrayToObject = arr =>
  arr.reduce(function (res, item) {
    let key = Object.keys(item)[0]
    res[key] = item[key]
    return res
  }, {})

const convertObjectToQuerystring = function(obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        convertObjectToQuerystring(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}
