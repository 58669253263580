import { styled, TableCell as MuiTableCell } from '@mui/material'

import { BsFillTriangleFill } from 'react-icons/bs'

interface ITableCell {
  columns?: number
  active?: number
}

interface IIconSort {
  order: 'asc' | 'desc' | ''
}

export const TableCell = styled(MuiTableCell)<ITableCell>(({ theme, columns, active }) => ({
  width: !!columns ? `calc(100% / ${columns})` : 'fit-content',

  fontWeight: 'bold',

  cursor: !!columns ? (!!active ? 'pointer' : 'not-allowed') : '',
  userSelect: 'none'
}))

export const IconSort = styled(BsFillTriangleFill)<IIconSort>(({ theme, order }) => ({
  fill: theme.palette.text.primary,

  marginLeft: theme.spacing(0.7),

  transition: 'transform .12s ease!important',
  transform: `rotate(${order === 'desc' ? '180deg' : '0'})`
}))
