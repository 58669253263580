import React from 'react'

// * Components
import { ListItem, ListItemButton, ListItemText } from '@mui/material'

// * Styled Components
import { Link } from './menu-item.styles'

// * Contexts
import { DataBaseContext } from 'contexts'

interface IMenuItem {
  route: string
  onClick?: () => void
}

export const MenuItem: React.FC<IMenuItem> = ({ route, onClick }) => {
  const { currentTable } = React.useContext(DataBaseContext)
  const { nameParsed } = currentTable(route)

  return (
    <ListItem disablePadding onClick={onClick || undefined}>
      <Link to={route}>
        <ListItemButton>
          <ListItemText primary={nameParsed} />
        </ListItemButton>
      </Link>
    </ListItem>
  )
}
