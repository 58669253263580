import React from 'react'
import { FormControl, styled, TextField } from '@mui/material'
import { DatePickerProps as MUIDatePickerProps, MobileDatePicker } from '@mui/x-date-pickers'
import { Controller } from 'react-hook-form'

type DatePickerProps = MUIDatePickerProps<any, any> & {
  label?: string
  id?: string
  error?: any
  originalData?: any
  required?: boolean
}

type InputProps = Partial<DatePickerProps> & {
  name: string
  control: any
  register: any
  originalData?: any
}

export const CustomDatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(({ error, ...props }, ref) => {
  //@ts-ignore
  const [date, setDate] = React.useState<Date | null>(props?.checked)

  const handleChange = (newValue: any) => {
    setDate(newValue)
  }

  const TextFieldWithProps = (params: any) => {
    return (
      <TextField
        {...props}
        margin="normal"
        variant="outlined"
        type="text"
        helperText={error?.message}
        InputLabelProps={{
          shrink: true
        }}
        ref={ref}
        {...params}
        error={!!error}
      />
    )
  }

  return (
    <CustomFormControl>
      <CustomMobileDatePicker
        label={props.label}
        inputRef={ref}
        value={date || ''}
        inputFormat="dd/MM/yyyy"
        onChange={handleChange}
        renderInput={TextFieldWithProps}
      />
    </CustomFormControl>
  )
})

const CustomInput = ({ name, control, register, ...props }: InputProps) => {
  if (!name || !control || !register) {
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <CustomDatePicker {...props} {...register(name)} />}
    />
  )
}

export const DatePicker = styled(CustomInput)({
  boxSizing: 'border-box',

  '.MuiFormLabel-asterisk': {
    color: 'red'
  }
})

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  width: 'calc(50% - 16px)',
  margin: theme.spacing(1),

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: '100%'
  }
}))

const CustomMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({}))
