import React from 'react'

import { AuthProvider } from 'contexts/auth.context'
import { ToastProvider } from 'contexts/toast.context'
import { LoadingContext } from 'contexts/loading.context'

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  return (
    <AuthProvider>
      <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        <ToastProvider>{children}</ToastProvider>
      </LoadingContext.Provider>
    </AuthProvider>
  )
}
