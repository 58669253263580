import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

// * Components
import { Button, Input } from 'components'

// * Styled Components
import { Container, Form, Title } from './login.styles'

// * Contexts
import { useAuth, useToast, ToastTypes, DataBaseContext } from 'contexts'

const schema = yup.object().shape({
  email: yup.string().email('Deve ser um e-mail válido').required('E-mail precisa ser informado'),
  password: yup
    .string()
    .min(8, 'Senha precisa ser de 8 caracteres')
    .max(32, 'Senha precisa ter no máximo 32 caracteres')
    .required('Senha requirida')
})

type FormValues = {
  email: string
  password: string
}

export const Login: React.FC = () => {
  const { allTables } = React.useContext(DataBaseContext)

  const { addToast } = useToast()
  const { signIn } = useAuth()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<FormValues>({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: FormValues) => {
    try {
      await signIn(data)

      addToast({
        type: ToastTypes.SUCCESS,
        title: 'Sucesso',
        description: 'Você efetuou o login'
      })

      reset()

      const nextRote = Object.keys(allTables).filter((key: string) => !allTables[key].isAbstract)
      navigate(`/${nextRote[0]}`)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Container>
      <Title variant="h2">Entrar</Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          control={control}
          label="Informe seu e-mail"
          required
          register={register}
          placeholder="teste@teste.com.br"
          error={errors['email']}
        />

        <Input
          name="password"
          control={control}
          label="Informe sua senha"
          required
          type={'password'}
          register={register}
          placeholder="******"
          error={errors['password']}
        />

        <Button variant={'gradient'} type={'submit'}>
          Entrar
        </Button>
      </Form>
    </Container>
  )
}
