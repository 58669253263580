import { Box, styled, TableCell as MUITableCell } from '@mui/material'

import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { IoMdRefresh } from 'react-icons/io'

export const RowActions = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 10px;
`

export const TableCell = styled(MUITableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],

  boxShadow: theme.shadows[6],

  position: 'sticky',
  right: 0
}))

export const IconContainer = styled(Box)(({ theme }) => ({
  height: '18px'
}))

export const IconRecover = styled(IoMdRefresh)(({ theme }) => ({
  transform: 'scaleX(-1)',
  transformOrigin: 'center',
  transformBox: 'fill-box',

  color: theme.palette.text.primary,
  cursor: 'pointer'
}))

export const IconEdit = styled(AiFillEdit)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer'
}))

export const IconDelete = styled(AiFillDelete)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer'
}))
