import { Box, Typography, styled } from '@mui/material'

import { BiArrowBack } from 'react-icons/bi'

export const Container = styled(Box)`
  width: 800px;
  height: 100vh;

  margin: 300px auto 0;

  display: flex;
  flex-direction: column;
`

export const BackContainer = styled(Box)`
  width: fit-content;

  display: flex;
  align-items: center;

  cursor: pointer;
`

export const IconBack = styled(BiArrowBack)(({ theme }) => ({
  fill: theme.palette.text.primary
}))

export const LabelBack = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}))

export const Title = styled(Typography)(({ theme }) => ({
  marginTop: '10px',

  color: theme.palette.text.primary
}))

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary
}))
