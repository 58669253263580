import { Box, styled, Typography } from '@mui/material'

import { AiFillDelete } from 'react-icons/ai'
import { BsTable } from 'react-icons/bs'

interface IContainerProps {
  active: number
  disabled?: number
}

export const Container = styled(Box)<IContainerProps>(({ theme, active, disabled }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.6),

  backgroundColor:
    (disabled && theme.palette.grey[500]) ||
    (theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]),
  borderBottom: `2px solid ${!!active ? theme.palette.primary.main : theme.palette.grey[500]}`,

  color: !!active ? theme.palette.primary.main : theme.palette.text.primary,

  cursor: disabled ? 'not-allowed' : 'pointer',
  transition: 'all .5s ease!important',

  boxSizing: 'border-box',

  '& svg': {
    fill: !!active ? theme.palette.primary.main : theme.palette.text.primary,
    transition: 'fill .5s ease!important'
  }
}))

export const IconTable = styled(BsTable)(({ theme }) => ({
  color: theme.palette.text.primary
}))

export const IconDump = styled(AiFillDelete)(({ theme }) => ({
  color: theme.palette.text.primary
}))

export const Label = styled(Typography)(({ theme }) => ({
  color: 'inherit',

  fontSize: '1rem'
}))
