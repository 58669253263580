import React from 'react'

// * Components
import { Box, Tooltip } from '@mui/material'

// * Styled Components
import { IconLogout } from './logout.styles'

// * Contexts
import { useAuth } from 'contexts'

export const Logout: React.FC = () => {
  const { signOut } = useAuth()

  return (
    <Tooltip title="Logout" arrow placement="top">
      <Box sx={{ width: 'fit-content', height: '23px' }}>
        <IconLogout size={23} onClick={signOut} color="#000" />
      </Box>
    </Tooltip>
  )
}
