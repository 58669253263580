import React from 'react'

import { Container, Loader } from './loading.styles'

type ILoadingProps = {
  size: number
}

export const Loading: React.FC<ILoadingProps> = ({ size }) => {
  return (
    <Container>
      <Loader size={size} />
    </Container>
  )
}
