import { PaletteMode } from '@mui/material'

const CUSTOM_PALETTE = {
  primary: {
    main: '#ff026f'
  },
  secondary: {
    main: '#ff6225'
  }
}

const TOAST_PROPS = {
  toast: {
    success: {
      background: '#e6fffa',
      color: '#2e656a'
    },
    error: {
      background: '#fddede',
      color: '#c53030'
    },
    info: {
      background: '#ebf8ff',
      color: '#2e656a'
    }
  }
}

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...CUSTOM_PALETTE
  },
  ...TOAST_PROPS
})
