import { styled, Typography } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  boxSizing: 'border-box',

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    padding: `0 ${theme.spacing(4)}`
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  width: '458px',
  color: theme.palette.text.primary,
  fontSize: '2rem',
  fontWeight: 'bold',

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: '100%'
  }
}))

export const Form = styled('form')(({ theme }) => ({
  width: 'fit-content',

  marginTop: '15px',
  gap: '20px',

  display: 'flex',
  flexDirection: 'column',

  '& .MuiFormControl-root': {
    width: '450px'
  },

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: '100%',

    '& .MuiFormControl-root': {
      width: '100%',
      margin: 0
    }
  }
}))
