// @ts-nocheck
import React from 'react'

// * Services
import { getDBData } from 'services/api'

// * Types
import { Table, TableModified } from 'types/table.type'

// * Constants
import { HIDDEN_COLUMNS, OLD_REMOVABLE_COLUMNS } from 'constants/removable-columns.constants'

// * Utils
import { convertToBDColumnName } from 'utils/backend-types-converter'
import { capitalize } from 'utils/capitalize'

type ObjectTable = {
  table: Record<Table['name'], Table>
}

export const DataBaseContext = React.createContext<Record<string, any>>({} as Table)
DataBaseContext.displayName = 'DataBaseContext'

export const DataBaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const rawDBData = useRAWDBData()
  const addNewKeysOnTables = addKeysOnTable(rawDBData)
  const addKeysOnColumns = addKeysOnColumnsTables(addNewKeysOnTables)

  let allTables = convertTableToObject(addKeysOnColumns)

  const mergeRelationTables = addKeysOnColumns.map(table => mergeTablesWithRelations(table, allTables))
  const filterAbstract = mergeRelationTables.filter(({ isAbstract }: Table) => !isAbstract)
  const filteredAbstractTables = convertTableToObject(filterAbstract)
  const filteredRemovableColumns = filterRemovableColumns(mergeRelationTables)

  allTables = convertTableToObject(filteredRemovableColumns)
  const getCurrentTable = (tableName: string): Table => allTables[tableName]

  const providerValue = {
    raw: rawDBData,
    allTables,
    filteredAbstractTables,
    convertTableToObject,
    visibleColumns,
    hiddenColumns,
    currentTable: getCurrentTable
  }
  return <DataBaseContext.Provider value={providerValue}>{children}</DataBaseContext.Provider>
}

/**
 * Retorna o schema de tabelas do DB.
 * @returns Table[]
 */
const useRAWDBData = (): Table[] => {
  const [raw, setRaw] = React.useState<Table[]>([])
  // @ts-ignore
  React.useMemo(() => getDBData().then(data => setRaw(data)), [])
  return raw
}

/**
 * Adiciona na tabela atual os dados da tabela relacionada.
 * @param table: Table
 * @param allTables: ObjectTable
 * @returns TableModified
 */
const mergeTablesWithRelations = (table: Table, allTables: ObjectTable): TableModified => {
  if (table?.relationships?.length > 0) {
    const tableColumns = table.columns
    const tableColumnsName = tableColumns.map(columns => columns.name)

    table.relationships.map((relation: Relation) => {
      const relationTable = allTables[relation.foreignTable]

      if (relation.type === 'isA') {
        const diffColumns = relationTable.columns.filter(column => !tableColumnsName.includes(column.name))

        if (relationTable!.relationships?.length > 0) {
          mergeTablesWithRelations(relationTable!, allTables)
        }
        table.columns.push(...diffColumns)
      }

      if (relation.type === 'belongsTo') {
        const columnWithRelationName = tableColumns.find(({ name }) => name === convertToBDColumnName(relation.column))
        columnWithRelationName.isRelationKey = true
        columnWithRelationName.isReadOnly = columnWithRelationName.isUnique && columnWithRelationName?.isPrimaryKey
        columnWithRelationName.relationValue = {
          name: relation.column.split('_')[0],
          type: 'relationship',
          columns: relationTable?.columns || [],
          relationTable: relation.foreignTable,
          relationApi: relation.foreignTable.replaceAll('_', '-'),
          relationColumn: relation.foreignColumn
        }

        if (relationTable.isAbstract && relationTable.hasChilds) {
          columnWithRelationName.relationValue.relationChildrenApis = relationTable.discriminatorColumnValues.map(
            column => relationTable.discriminatorMapping[column].replaceAll('_', '-')
          )
        }
      }

      return table
    })
  }

  return table
}

const convertTableToObject = (table: Table): ObjectTable => {
  return table.reduce((a, v) => ({ ...a, [v.name]: v }), {})
}

/**
 * Adiciona chaves customizadas nas tabelas.
 * @param tables - TableModified
 * @returns TableModified
 */
const addKeysOnTable = tables => {
  return tables.map(table => {
    const hasMultipleKeys = table.indexes.find(values => values.primaryKey && values.fields.length > 1)
    table.hasMultiplePrimaryKey = !!hasMultipleKeys
    table.formattedApi = table.name.replaceAll('_', '-')
    table.nameParsed = capitalize(table.name.replaceAll('_', ' ').replaceAll('-', ' '))
    table.externalColumns = table.columns.filter(column => column.isRelationKey)
    table.externalColumnsNames = table.externalColumns.map(({ name }) => name)
    table.externalColumnsTotal = table.externalColumnsNames.length
    table.isRelationalTable = table.indexes.filter(index => index.fields.length > 1 && index.primaryKey).length > 0
    table.childrenTables = table.isAbstract
      ? Object.values(table.discriminatorMapping).map(tableName => convertTableToObject(tables)?.[tableName])
      : []

    return table
  })
}

/**
 * Adiciona chaves customizadas nas colunas das tabelas.
 * @param tables - TableModified[]
 * @returns TableModified[]
 */
const addKeysOnColumnsTables = (allTables): TableModified[] =>
  allTables.map(table => {
    table.columns.map(column => {
      column.name = convertToBDColumnName(column.name) ?? column.name
      column['isRelationKey'] = false
      column['isReadOnly'] = false
      column['isHidden'] = HIDDEN_COLUMNS.includes(convertToBDColumnName(column.name))
      column['relationValue'] = {}
      column['relationTable'] = null
      column['relationApi'] = null
      column['relationChildrenApis'] = null
      column['relationColumn'] = null
      return column
    })
    return table
  })

/**
 * Filtra apenas as colunas que podem ser exibidas na tela.
 * @param table - TableModified
 * @returns ColumnModified[]
 */
const visibleColumns = (table: TableModified): ColumnModified[] => {
  return table?.columns
    .filter((column: ColumnModified) => !column.isHidden)
    .map((column: ColumnModified) => {
      if (column?.isRelationKey) {
        return {
          originalColumn: column,
          isRelationKey: true,
          ...column.relationValue
        }
      }
      return column
    })
}

/**
 * Filtra apenas as colunas que podem ser exibidas na tela.
 * @param table - TableModified
 * @returns ColumnModified[]
 */
const hiddenColumns = (table: TableModified): ColumnModified[] => {
  return table?.columns
    .filter((column: ColumnModified) => column.isHidden)
    .map((column: ColumnModified) => {
      if (column?.isRelationKey) {
        return {
          originalColumn: column,
          isRelationKey: true,
          ...column.relationValue
        }
      }
      return column
    })
}

const removableColumns = HIDDEN_COLUMNS.filter(x => !OLD_REMOVABLE_COLUMNS.includes(x)).concat(
  OLD_REMOVABLE_COLUMNS.filter(x => !HIDDEN_COLUMNS.includes(x))
)

/**
 * Filtra colunas que não são ocultas ou visíveis
 * @param tables
 * @returns TableModified
 */
const filterRemovableColumns = (tables): TableModified => {
  return tables.map(table => ({
    ...table,
    columns: table.columns.filter(({ name: columnName }) => !removableColumns.includes(columnName))
  }))
}
