import React from 'react'
import { Link } from 'react-router-dom'

// * Components
import { Tooltip } from '@mui/material'

// * Styled Components
import { IconContainer, IconDelete, IconEdit, IconRecover, RowActions, TableCell } from './table-actions.styles'

type ITableActionsProps = {
  rowId: number | string
  isDump: boolean
  handleDelete: (rowId: number | string, isDump: boolean) => void
  handleRecoverItem: (id: number | string) => void
}

export const TableActions: React.FC<ITableActionsProps> = ({ rowId, isDump, handleDelete, handleRecoverItem }) => {
  const id = typeof rowId === 'object' ? JSON.stringify(rowId) : rowId

  return (
    <TableCell sx={{ position: 'sticky', right: 0 }}>
      <RowActions>
        {!isDump ? (
          <Tooltip title="Editar" arrow placement="top">
            <Link to={`edit/${id}`} style={{ height: 18 }}>
              <IconEdit size={18} />
            </Link>
          </Tooltip>
        ) : (
          <Tooltip title="Recuperar" arrow placement="top">
            <IconContainer>
              <IconRecover size={18} onClick={() => handleRecoverItem(id)} />
            </IconContainer>
          </Tooltip>
        )}
        <Tooltip title="Remover" arrow placement="top">
          <IconContainer>
            <IconDelete size={18} onClick={() => handleDelete(id, isDump)} />
          </IconContainer>
        </Tooltip>
      </RowActions>
    </TableCell>
  )
}
