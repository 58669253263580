import React from 'react'
import { useLocation } from 'react-router-dom'

// * Components
import { Paper } from '@mui/material'
import { Tabs } from './components/tabs'
import { ListItems } from './pages/list-items'
import { RecycleBin } from './pages/recycle-bin'

// * Styled Components
import { Container, Description, Title } from './list.styles'

// * Types
import { Table } from 'types/table.type'
import { Response } from './types/response.type'

// * Services
import api from 'services/api'

// * Enums
import { CurrentPage } from './enums/current-page.enum'
import { DataBaseContext } from 'contexts'

interface IListProps {
  data: Table
}

export type OrderByColumnType = {
  column: string | ''
  order: 'asc' | 'desc' | ''
}

export const List: React.FC<IListProps> = ({ data }) => {
  const location = useLocation()
  const { currentTable } = React.useContext(DataBaseContext)

  const [orderByColumn, setOrderByColumn] = React.useState<OrderByColumnType>({ column: '', order: '' })
  const [currentPage, setCurrentPage] = React.useState<CurrentPage>(CurrentPage.ITEMS)
  const [hasItemsInDump, setHasItemsInDump] = React.useState<boolean>(false)
  const [deleted, setDeleted] = React.useState<boolean>(false)

  const { nameParsed } = currentTable(data.name)

  React.useEffect(() => {
    const getDump = async () => {
      const path = location.pathname.replace('/', '').replaceAll('_', '-')
      try {
        const { data }: Response = await api.get(`api/${path}/recycle-bin`)
        if (!!data.content.length) {
          setHasItemsInDump(false)
          setDeleted(false)
          return
        }

        setHasItemsInDump(true)
        setDeleted(false)
      } catch (error) {
        console.error(error)
        setHasItemsInDump(true)
        setDeleted(false)
      }
    }

    getDump()
  }, [location.pathname, deleted, currentPage])

  React.useEffect(() => {
    setOrderByColumn({ column: '', order: '' })
  }, [currentPage, setOrderByColumn])

  return (
    <Container>
      <Title variant="h2">{nameParsed}</Title>

      <Description variant="subtitle1">
        Gerencie os dados referentes ao(s) {nameParsed} registrado(s) no sistema FireDev.
      </Description>

      <Tabs hasItemsInDump={Number(hasItemsInDump)} currentPage={currentPage} setCurrentPage={setCurrentPage} />

      <Paper elevation={2} sx={{ mt: 4 }}>
        {currentPage === CurrentPage.ITEMS ? (
          <ListItems
            data={data}
            isDump={false}
            setDeleted={setDeleted}
            orderByColumn={orderByColumn}
            setOrderByColumn={setOrderByColumn}
          />
        ) : (
          <RecycleBin
            data={data}
            isDump={true}
            setDeleted={setDeleted}
            orderByColumn={orderByColumn}
            setOrderByColumn={setOrderByColumn}
          />
        )}
      </Paper>
    </Container>
  )
}
