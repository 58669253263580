import React from 'react'

// * Components
import Swal from 'sweetalert2'
import { FormComponent } from '../form'
import { BackContainer } from '../back-container'

// * Styled Components
import { Container, Title } from './new.styles'

// * Types
import { Table as TableType } from 'types/table.type'

// * Constants
import { Loading } from 'components/Loading'

// * Context
import { DataBaseContext, useToast, ToastTypes } from 'contexts'

// * hooks
import { useResponseApi } from 'hooks'

interface IEditProps {
  data: TableType
}
export const New: React.FC<IEditProps> = ({ data }) => {
  const { addToast } = useToast()
  const { currentTable, visibleColumns: getVisibleColumns } = React.useContext(DataBaseContext)
  const { getCreateData, createDataOnAPI } = useResponseApi()

  const [rows, setRows] = React.useState<Record<string, any> | {}>({})
  const [hasPageError, setHasPageError] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const table = currentTable(data.name)
  const visibleColumns = getVisibleColumns(table)

  React.useEffect(() => {
    setIsLoading(true)

    getCreateData({ table })
      .then(data => {
        setRows(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [table, getCreateData])

  if (visibleColumns.length <= 0) {
    setHasPageError(true)
  }

  React.useEffect(() => {
    if (hasPageError) {
      const showErrorAlert = () => {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro no Servidor.',
          html: 'Ocorreu um erro no servidor, por favor tente mais tarde.'
        })
      }

      showErrorAlert()
    }
  }, [hasPageError])

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    return createDataOnAPI({
      values: values,
      table: table,
      originalResponse: {}
    })
      .then(data => {
        addToast({
          type: ToastTypes.SUCCESS,
          title: 'Sucesso',
          description: 'Cadastro criado com sucesso!'
        })
      })
      .catch(({ error, submitData }) => {
        if (error.response.status === 417) {
          const campo = error.response.data.message
            .split(`PUBLIC.${data.name.toUpperCase()}`)[1]
            .split('VALUES')[0]
            .replace(/\(|\)/g, '')
            .toLowerCase()
            .split(' ')

          addToast({
            type: ToastTypes.ERROR,
            title: 'Falha ao criar cadastro.',
            description: `O valor ${submitData[campo[0]]} do campo ${campo[0]} já existe!`
          })

          return Promise.reject(campo)
        }

        return Promise.reject(error.response)
      })
  }

  return hasPageError ? (
    <></>
  ) : isLoading ? (
    <Loading size={80} />
  ) : (
    <Container>
      <BackContainer />

      <Title variant="h1">Adicionar {table.nameParsed}</Title>

      <FormComponent onSubmit={onSubmit} rows={rows} columns={visibleColumns} sendButtonValue="Cadastrar" />
    </Container>
  )
}
