import { Box, styled, Typography } from '@mui/material'

// * Icons
import { BiArrowBack } from 'react-icons/bi'

export const Container = styled(Box)`
  width: fit-content;

  display: flex;
  align-items: center;

  gap: 5px;

  cursor: pointer;
`

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}))

export const IconBack = styled(BiArrowBack)(({ theme }) => ({
  fill: theme.palette.text.primary
}))
