import * as yup from 'yup'
import type { AnyObjectSchema } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { getType } from 'utils/backend-types-converter'
import { ColumnModified } from 'types/table.type'
import { parse, isDate } from 'date-fns'

export function useFormValidate(data: ColumnModified[]) {
  const normalizedData = data.map(normalizeInputToSchema) as normalizedDataT[]
  // @ts-ignore
  const schema = normalizedData.reduce(createYupSchema, {}) as AnyObjectSchema<normalizedDataT>

  const { register, handleSubmit, control, formState, setError, reset } = useForm({
    resolver: yupResolver(yup.object().shape(schema))
  })

  return {
    register,
    handleSubmit,
    control,
    formState,
    setError,
    reset,
    normalizedData
  }
}

type normalizedDataT = ColumnModified & {
  id: string
  validationType: string
  required: boolean
  validations: Record<string, any>[]
}
function normalizeInputToSchema(data: ColumnModified): normalizedDataT | {} {
  if (!data) {
    return {}
  }

  if (data.type === 'relationship') {
    // @ts-ignore
    const isRequired = data.originalColumn?.isNotNull || data.originalColumn?.notNull || false
    const dataType = getType({ type: data.originalColumn?.type ?? 'number' })
    const validationObj = {
      id: data.name,
      type: 'relationship',
      validationType: dataType,
      required: isRequired,
      validations: [
        isRequired
          ? {
              type: 'required',
              params: ['Este campo é obrigatório.']
            }
          : {},
        dataType === 'string' && {
          type: 'min',
          params: [1, `${data.name} precisa de pelo menos 1 character`]
        },
        dataType === 'string' && {
          type: 'trim',
          params: [`${data.name} não pode ser vazio.`]
        }
      ].filter(Boolean)
    }

    return Object.assign(data, validationObj)
  }

  if (data.type === 'enum') {
    // @ts-ignore
    const isRequired = data.originalColumn?.isNotNull || data.originalColumn?.notNull || false
    const dataType = getType({ type: data.type })
    const validationObj = {
      id: data.name,
      type: dataType,
      validationType: 'string',
      required: isRequired,
      validations: [
        isRequired
          ? {
              type: 'required',
              params: ['Este campo é obrigatório.']
            }
          : {}
      ].filter(Boolean)
    }

    return Object.assign(data, validationObj)
  }

  if (data.type === 'date') {
    //@ts-ignore
    const isRequired = data?.isNotNull || data?.notNull
    const dataType = getType({ type: data.type })
    const validationObj = {
      id: data.name,
      type: dataType,
      validationType: dataType,
      required: isRequired,
      validations: [
        isRequired
          ? {
              type: 'required',
              params: ['Este campo é obrigatório.']
            }
          : {}
      ].filter(Boolean)
    }
    return Object.assign(data, validationObj)
  }

  //@ts-ignore
  const isRequired = data?.isNotNull || data?.notNull
  const dataType = getType({ type: data.type })
  const validationObj = {
    id: data.name,
    type: dataType,
    validationType: dataType,
    required: isRequired,
    validations: [
      isRequired
        ? {
            type: 'required',
            params: ['Este campo é obrigatório.']
          }
        : {},
      dataType === 'string' && {
        type: 'min',
        params: [1, `${data.name} precisa de pelo menos 1 character`]
      },
      dataType === 'string' && {
        type: 'trim',
        params: [`${data.name} não pode ser vazio.`]
      },
      dataType === 'date' && {
        type: 'date',
        transform: parseDateString
      }
    ].filter(Boolean)
  }
  return Object.assign(data, validationObj)
}

/**
 * Create yup schema dinamically.
 * @param schema
 * @param config
 * @see: https://github.com/jquense/yup/issues/559#issuecomment-537460952
 */
function createYupSchema(schema: any, config: normalizedDataT) {
  const { id, validationType, validations = [] } = config
  // @ts-ignore
  if (!yup[validationType]) {
    return schema
  }
  // @ts-ignore
  let validator = yup[validationType]()

  if (validationType === 'date') {
    validator.transforms.push((value: any, originalValue: any) => {
      // originalValue = "10/12/2022"
      try {
        let date = originalValue.split('/')
        // date = ["10", "12", "2022"] <- day, month and year respectively

        if (date.length === 3) {
          let newDate = `${date[2]}-${date[1]}-${date[0]}`
          return new Date(newDate)
        }
        return null
      } catch (e) {
        return null
      }
    })
  }

  validations.forEach(validation => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    validator = validator[type](...params)
  })
  schema[id] = validator
  return schema
}

function parseDateString(value: any, originalValue: any): any {
  console.info({ value }, { originalValue })
  const parsedDate = isDate(originalValue) ? new Date(originalValue) : parse(originalValue, 'yyyy-MM-dd', new Date())

  return parsedDate
}
