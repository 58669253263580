import React from 'react'
import { Drawer as MUIDrawer, IconButton, List } from '@mui/material'

// * Components
import { MenuItem } from '../menu-item'
import { ThemeSwitcher } from 'components/ThemeSwitcher'

// * Styled Components
import { Bar, Title, Toolbar } from './drawer.styles'

// * Icons
import { AiOutlineMenu as IconMenu } from 'react-icons/ai'
import { Logout } from 'components/Logout/logout.component'

interface IMenuItem {
  routes: string[]
  userIsLogged: boolean
}

export const Drawer: React.FC<IMenuItem> = ({ routes, userIsLogged }) => {
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)

  return (
    <>
      <Bar>
        <Toolbar>
          {userIsLogged && (
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
              <IconMenu />
            </IconButton>
          )}

          <Title variant="h2">FireDev</Title>

          <ThemeSwitcher size={20} shadow={false} sx={{ marginRight: 1.5 }} />

          {userIsLogged && <Logout />}
        </Toolbar>
      </Bar>

      <MUIDrawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          {routes.map((route, index) => (
            <MenuItem key={index} route={route} onClick={() => setOpenDrawer(false)} />
          ))}
        </List>
      </MUIDrawer>
    </>
  )
}
