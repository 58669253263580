import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// * Components
import { ThemeSwitcher } from 'components/ThemeSwitcher'

// * Contexts
import { AppProvider, DataBaseProvider, ColorModeProvider } from 'contexts'

// * Routes
import { MainRoutes } from 'routes'

// * Themes
import { GlobalStyles } from 'styles/global-styles.component'
import { LocalizationProvider } from '@mui/x-date-pickers'

export const App: React.FC = () => {
  return (
    <DataBaseProvider>
      <ColorModeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppProvider>
            <GlobalStyles />
            <BrowserRouter>
              <MainRoutes />
            </BrowserRouter>
            <ThemeSwitcher
              size={20}
              shadow={true}
              sx={{
                position: 'fixed',
                right: 25,
                bottom: 25,
                zIndex: 10,
                '@media screen and (max-width: 600px)': { display: 'none' }
              }}
            />
          </AppProvider>
        </LocalizationProvider>
      </ColorModeProvider>
    </DataBaseProvider>
  )
}
