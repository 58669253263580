import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 50px;
  right: 15px;
  z-index: 1000;

  @media screen and (max-width: 600px) {
    top: 75px;
  }
`
