// @ts-nocheck
import React from 'react'

// * Components
import { Table } from '../../shared/table'
import { Pagination } from '../../shared/pagination'

// * hooks
import { useResponseApi } from 'hooks'

// * Contexts
import { DataBaseContext, LoadingContext } from 'contexts'

// * Types
import { Table as TableType } from 'types/table.type'

interface IRecycleBinProps {
  data: TableType
  isDump: boolean
  orderByColumn: OrderByColumnType
  setDeleted(isDeleted: boolean): void
  setOrderByColumn(arg: OrderByColumnType): void
}

export const RecycleBin: React.FC<IRecycleBinProps> = ({
  data,
  isDump,
  orderByColumn,
  setDeleted,
  setOrderByColumn
}) => {
  const [page, setPage] = React.useState<number>(0)
  const [totalPages, setTotalPages] = React.useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)
  const [rows, setRows] = React.useState<any[]>([])
  const [totalItems, setTotalItems] = React.useState<number>(0)

  const [filterText, setFilterText] = React.useState<string>('')

  const { setIsLoading } = React.useContext(LoadingContext)
  const { currentTable, visibleColumns } = React.useContext(DataBaseContext)
  const table = currentTable(data.name)
  const { getRecycleListData } = useResponseApi()

  const columns = visibleColumns(table)

  const updateStates = React.useCallback((rows: any[], totalPages: number, totalItems: number, page: number) => {
    setRows(rows)
    setTotalPages(totalPages)
    setTotalItems(totalItems)
    setPage(page)

    if (page) {
      setPage(page)
    }
  }, [])

  React.useEffect(() => {
    setIsLoading(true)

    const hasOrder = !!Object.values(orderByColumn).filter(value => !!value).length
    const { column, order } = orderByColumn
    const orderBy = hasOrder ? `${column}:${order}` : ''

    getRecycleListData({
      requestApi: table.formattedApi,
      page,
      rowsPerPage,
      filterText,
      orderBy,
      table: table
    })
      .then(response => {
        if (!response.content.length) {
          setRows([])
          setIsLoading(false)

          return
        }

        updateStates(response.content, response.totalPages, response.totalElements, response.number)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    filterText,
    page,
    rowsPerPage,
    setIsLoading,
    updateStates,
    currentTable,
    table,
    getRecycleListData,
    orderByColumn
  ])

  return (
    <>
      <Table
        tableData={table}
        columns={columns}
        rows={rows}
        filterText={filterText}
        isDump={isDump}
        orderByColumn={orderByColumn}
        setDeleted={setDeleted}
        setOrderByColumn={setOrderByColumn}
        setFilterText={setFilterText}
        updateStates={updateStates}
      />

      <Pagination
        count={totalItems}
        page={page}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setTotalPages={setTotalPages}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  )
}
