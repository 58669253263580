import React from 'react'

import { ColumnModified } from 'types/table.type'
import { Button, Input, Switch, Select } from 'components'
import { Paper } from '@mui/material'
import { useFormValidate } from 'hooks/form-validate.hook'
import { useNavigate } from 'react-router-dom'
import { ButtonContainer, Form } from './form.styles'
import { capitalize } from 'utils/capitalize'
import { DatePicker } from 'components/DatePicker'

interface FormProps {
  onSubmit: Function
  rows: Record<string, any> | {}
  columns: ColumnModified[]
  sendButtonValue: string
  editView?: boolean
}

export const FormComponent: React.FC<FormProps> = ({ onSubmit, rows, columns, sendButtonValue, editView }) => {
  const navigate = useNavigate()
  const getForm = useFormValidate(columns)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
    normalizedData: schema
  } = getForm

  const onFormSubmit = (values: Record<string, any>) => {
    onSubmit(values)
      .then(() => {
        reset()
        navigate(-1)
      })
      .catch((e: string[]) => {
        setError(e[0], { type: 'custom', message: 'Esse valor já existe.' })
      })
  }

  return (
    <Paper elevation={2} sx={{ mt: 4, padding: 2 }}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        {schema.map((column: (typeof schema)[0]) => {
          return (
            <FormPopulate
              data={column}
              key={column.name}
              // @ts-ignore
              {...(rows?.[column.name] !== undefined ? { initialValue: rows[column.name] } : {})}
              register={register}
              control={control}
              error={errors}
              editView={editView}
            />
          )
        })}

        <ButtonContainer>
          <Button type={'submit'}>{sendButtonValue}</Button>
        </ButtonContainer>
      </Form>
    </Paper>
  )
}

export function FormPopulate(props: Record<string, any>): JSX.Element | null {
  const {
    control,
    register,
    initialValue,
    editView = false,
    error,
    data: { type, name, required, originalColumn }
  } = props

  if (['string', 'number', 'time'].includes(type)) {
    return (
      <Input
        control={control}
        error={error[name]}
        {...(initialValue !== undefined ? { defaultValue: initialValue } : {})}
        label={capitalize(name)}
        name={name}
        type={type}
        register={register}
        required={required}
        {...(type === 'time' ? { isTime: true } : {})}
        originalData={props.data}
      />
    )
  }

  if (['relationship', 'enum'].includes(type)) {
    let selectValues = initialValue

    if (type === 'enum') {
      if (selectValues) {
        selectValues = {
          id: initialValue,
          options:
            props.data.enumValues?.map((item: any) => ({
              id: item.toUpperCase(),
              nome: item
            })) || undefined
        }
      } else {
        selectValues = {
          options:
            props.data.enumValues?.map((item: any) => ({
              id: item.toUpperCase(),
              nome: item
            })) || undefined
        }
      }
    }

    if (selectValues?.options === undefined) {
      return <></>
    }

    return (
      <Select
        control={control}
        error={error[name]}
        {...(selectValues !== undefined ? { defaultValue: selectValues } : {})}
        label={name}
        name={name}
        type={type}
        register={register}
        required={required}
        disabled={(editView && originalColumn?.isReadOnly) || false}
      />
    )
  }

  if (type === 'boolean') {
    return (
      <Switch
        control={control}
        error={error[name]}
        {...(initialValue !== undefined ? { checked: initialValue } : {})}
        label={capitalize(name)}
        name={name}
        type={type}
        register={register}
        required={required}
      />
    )
  }

  if (['date', 'timestamp'].includes(type)) {
    return (
      <DatePicker
        control={control}
        error={error[name]}
        {...(initialValue !== undefined ? { checked: initialValue } : {})}
        label={capitalize(name)}
        name={name}
        register={register}
        required={required}
      />
    )
  }

  return null
}
