export const HIDDEN_COLUMNS = ['id', 'createdAt']

export const REMOVABLE_COLUMNS = [
  'ID',
  'CREATEDAT',
  'UPDATEDAT',
  'DELETEDAT',
  'CREATED_AT',
  'UPDATED_AT',
  'DELETED_AT'
]

export const OLD_REMOVABLE_COLUMNS = [
  'id',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'updated_at',
  'deleted_at'
]
