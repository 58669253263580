import React from 'react'

// * Components
import { TableRow } from '@mui/material'

// * Styled Compoenents
import { EmptyLabel } from './empty-list.styles'

interface IEmptyListProps {
  label: string
}

export const EmptyList: React.FC<IEmptyListProps> = ({ label }) => {
  return (
    <TableRow>
      <EmptyLabel colSpan={100}>{label}</EmptyLabel>
    </TableRow>
  )
}
