import React from 'react'
import { useNavigate } from 'react-router'

// * Styled components
import { Container, IconBack, Label } from './back-container.styles'

export const BackContainer: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Container onClick={() => navigate(-1)}>
      <IconBack />
      <Label variant="subtitle1">Voltar</Label>
    </Container>
  )
}
