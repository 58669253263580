import React from 'react'
import { useLocation } from 'react-router-dom'

// * Components
import { Table as MUITable, TableBody, TableCell, TableRow } from '@mui/material'
import { Loading } from 'components/Loading'
import { TableHeader, HeaderActions, TableItem, TableActions, EmptyList } from './components'
import { Confirm } from 'components/Alert/confirm/confirm'

// * Styled Components
import { TableContainer } from './table.styles'

// * Contexts
import { LoadingContext } from 'contexts/loading.context'
import { DataBaseContext } from 'contexts'

// * Hooks
import { useResponseApi } from 'hooks'

// * Types
import { Table as TableType } from 'types/table.type'
import { OrderByColumnType } from '../../list.component'

interface ITableProps {
  tableData: TableType
  columns: any[]
  rows: any[]
  filterText: string
  isDump: boolean
  orderByColumn: OrderByColumnType
  setDeleted(isDeleted: boolean): void
  setFilterText: (value: string) => void
  updateStates(rows: any[], totalPages: number, totalItems: number, page?: number, columns?: any[]): void
  setOrderByColumn(args: OrderByColumnType): void
}

export const Table: React.FC<ITableProps> = ({
  tableData,
  columns,
  rows,
  filterText,
  isDump,
  orderByColumn,
  setDeleted,
  setFilterText,
  updateStates,
  setOrderByColumn
}) => {
  const { isLoading } = React.useContext(LoadingContext)
  const { currentTable } = React.useContext(DataBaseContext)

  const location = useLocation()
  const table = currentTable(tableData.name)

  const { deleteItem, recoverItemFromDump } = useResponseApi()

  const handleDelete = async (id: string | number, isDump = false) => {
    const shouldDelete = (await Confirm({ title: 'Você tem certeza que quer deletar este item?', icon: 'warning' }))
      .isConfirmed
    if (!shouldDelete) return

    try {
      const { data }: any = await deleteItem({ id, tableName: table!.formattedApi, isDump })
      setDeleted(true)
      updateStates(data.content, data.totalPages, data.totalElements, 0)
    } catch (error: any) {
      const { data } = error
      setDeleted(false)
      updateStates(data.content, data.totalPages, data.totalElements, 0)
    }
  }

  const handleRecoverItem = async (id: string | number) => {
    const shouldRecoverItem = (
      await Confirm({ title: 'Você tem certeza que deseja recuperar esse item?', icon: 'info' })
    ).isConfirmed
    if (!shouldRecoverItem) return

    try {
      const { data }: any = await recoverItemFromDump({ table, id })
      updateStates(data.content, data.totalPages, data.totalElements, 0)
    } catch (error: any) {
      console.log(error)
      const { data } = error
      updateStates(data.content, data.totalPages, data.totalElements, 0)
    }
  }

  return (
    <>
      <HeaderActions filterText={filterText} setFilterText={setFilterText} />

      <TableContainer>
        <MUITable stickyHeader aria-label="sticky table">
          <TableHeader columns={columns} orderByColumn={orderByColumn} setOrderByColumn={setOrderByColumn} />

          <TableBody>
            {!isLoading ? (
              <>
                {!!rows.length ? (
                  <>
                    {rows?.map(row => (
                      <TableRow hover tabIndex={0} key={`${location.pathname}-${JSON.stringify(row.id)}`}>
                        <TableItem columns={columns} row={row} />
                        <TableActions
                          rowId={row.id}
                          isDump={isDump}
                          handleDelete={handleDelete}
                          handleRecoverItem={handleRecoverItem}
                        />
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <EmptyList label="Não há itens para serem exibidos" />
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Loading size={80} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
    </>
  )
}
