import { Box, styled } from '@mui/material'

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',

  boxSizing: 'border-box'
}))

export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2)
}))
