import axios from 'axios';
import Swal from 'sweetalert2';
import { Table } from "../types/table.type";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

api.interceptors.response.use(
  response => response,
  error => {
    if(error.response.status >= 500 ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: parserError(error.response?.data),
      })
    }
    return Promise.reject(error);
});

export default api

function parserError(data: any) {
  const errors = data?.data
  if (!errors) {
    return data?.message ?? 'Erro na requisição'
  }
  let textReturn = data?.message + '<br/>'

  Object.keys(errors).forEach(item => {
    textReturn += errors[item] + '<br/>'
  })
  return textReturn
}


export const apiDB = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers: {
      'Content-type': 'application/json'
    }
})

export const getDBData = (): Promise<Table[]> => apiDB
  .get('/mock.json')
  .then(({data}) => data)
