import { styled, Typography } from '@mui/material'
import { animated } from 'react-spring'

interface ContainerProps {
  type: 'success' | 'error' | 'info'
}

export const ToastContainer = styled(animated.div)<ContainerProps>(({ theme, type }) => ({
  width: '320px',

  padding: '16px 30px 16px 16px',

  display: 'flex',

  backgroundColor: theme.toast[type].background,
  color: theme.toast[type].color,

  borderRadius: '10px',

  boxShadow: theme.shadows[6],

  position: 'relative',
  boxSizing: 'border-box',

  '& + div': {
    marginTop: '15px'
  },

  div: {
    flex: 1,
    p: {
      marginTop: '4px',
      fontSize: '14px',
      lineHeight: '20px'
    }
  },

  button: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    opacity: 0.6,
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    color: 'inherit'
  },

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: '250px'
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: '1.2rem'
  }
}))
