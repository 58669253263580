import { Box, Input, styled } from '@mui/material'

export const ContainerActions = styled(Box)(({ theme }) => ({
  width: '100%',

  borderBottom: `1px solid ${theme.palette.grey[300]}`,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  boxSizing: 'border-box'
}))

export const InputContainer = styled(Box)`
  width: 250px;
  height: 52px;

  display: flex;
  align-items: center;

  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    width: 200px;
  }

  @media screen and (max-width: 400px) {
    width: 150px;
  }
`

export const SearchInput = styled(Input)`
  width: 100%;

  & svg {
    margin-right: 7px;
  }
`
