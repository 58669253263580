import React from 'react'

// * Components
import { TableHead, TableRow } from '@mui/material'

// * Styled Components
import { IconSort, TableCell } from './table-header.styles'

// * Utils
import { capitalize } from 'utils/capitalize'

// * Types
import { OrderByColumnType } from '../../../../list.component'

interface ITableHeaderProps {
  columns: any[]
  orderByColumn: OrderByColumnType
  setOrderByColumn(args: OrderByColumnType): void
}

export const TableHeader: React.FC<ITableHeaderProps> = ({ columns, orderByColumn, setOrderByColumn }) => {
  const handleClick = (columnData: any) => {
    const isReadOnly = columnData?.originalColumn?.isReadOnly
    if (isReadOnly) return

    const { column, order } = orderByColumn

    const columnName = columnData?.name
    if (columnName === column && order !== 'asc') {
      setOrderByColumn({ column: columnName, order: 'asc' })
      return
    }

    setOrderByColumn({ column: columnName, order: 'desc' })
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell
            key={column.name}
            columns={columns.length}
            onClick={() => handleClick(column)}
            active={column?.originalColumn?.isReadOnly ? 0 : 1}
          >
            {capitalize(column.name)}
            {orderByColumn.column === column.name && <IconSort order={orderByColumn.order || 'asc'} size={10} />}
          </TableCell>
        ))}
        {!!columns.length && <TableCell>Ações</TableCell>}
      </TableRow>
    </TableHead>
  )
}
