// * Components
import { Box, Paper, Typography, styled } from '@mui/material'

// * Constants
import { MENU } from './menu.constants'

export const ContainerWithShadow = styled(Paper)`
  width: ${MENU.VERTICAL.WIDTH};
  height: 100vh;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const TitleContainer = styled(Box)`
  width: 100%;
  text-align: center;
`

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.5rem',
  fontWeight: 'bold'
}))

export const MenuContainer = styled(Box)`
  height: 100%;
  overflow-y: auto;
`

export const LogoutContainer = styled(Box)`
  display: flex;
  justify-content: center;

  text-align: center;
`
