import { Box, styled } from '@mui/material'

interface ILoaderProps {
  size: number
}

export const Container = styled(Box)`
  width: 100%;
  height: 400px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    height: 200px;
  }
`

export const Loader = styled(Box)<ILoaderProps>(({ theme, size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  border: `8px solid ${theme.palette.common.white}`,
  borderTop: `8px solid ${theme.palette.common.black}`,
  borderRight: `8px solid ${theme.palette.common.black}`,
  borderBottom: `8px solid ${theme.palette.common.black}`,
  borderRadius: '50%',
  animation: `spin 0.7s linear infinite`,

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: `20px`,
    height: `20px`,
    border: `4px solid ${theme.palette.common.white}`,
    borderTop: `4px solid ${theme.palette.common.black}`,
    borderRight: `4px solid ${theme.palette.common.black}`,
    borderBottom: `4px solid ${theme.palette.common.black}`
  }
}))
