import React from 'react'

// * Components
import { TabItem } from './components/tab-item'

// * Styled Components
import { Container } from './tabs.styles'

// * Enums
import { CurrentPage } from '../../enums/current-page.enum'

interface ITabsProps {
  hasItemsInDump: number
  currentPage: CurrentPage
  setCurrentPage(currentPage: CurrentPage): void
}

export const Tabs: React.FC<ITabsProps> = ({ hasItemsInDump, currentPage, setCurrentPage }) => {
  return (
    <Container elevation={1}>
      <TabItem
        label="Itens"
        name={CurrentPage.ITEMS}
        currentPage={currentPage}
        onClick={() => setCurrentPage(CurrentPage.ITEMS)}
      />
      <TabItem
        label="Lixeira"
        name={CurrentPage.DUMP}
        disabled={hasItemsInDump}
        currentPage={currentPage}
        onClick={() => setCurrentPage(CurrentPage.DUMP)}
      />
    </Container>
  )
}
