/**
 * Converts be (JAVA) types to TS TYPES
 */
type getTypeProps = {
  type: string
}
export function getType({ type }: getTypeProps): string {
  if (['varchar(255)', 'text'].includes(type) || type?.startsWith('varchar')) {
    return 'string'
  }

  if (['float', 'int', 'bigInt', 'double', 'decimal'].includes(type)) {
    return 'number'
  }

  if (['timestamp', 'date'].includes(type)) {
    return 'date'
  }

  if (['time'].includes(type)) {
    return 'time'
  }

  return type
}

export function convertToBDColumnName(value: string): string {
  const splitedText = value?.split('_')
  let convertedText = value
  if (typeof splitedText === 'object') {
    for (let i = 0; splitedText.length > i; i++) {
      if (i === 0) {
        convertedText = splitedText[i]
      } else {
        convertedText += splitedText[i][0].toUpperCase() + splitedText[i].slice(1)
      }
    }
  }

  return convertedText
}
