import { Box, Typography, styled } from '@mui/material'

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',

  padding: theme.spacing(6.25),

  display: 'flex',
  flexDirection: 'column',

  boxSizing: 'border-box',

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    padding: `${theme.spacing(10)} ${theme.spacing(2.5)}`
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  width: '100%',

  color: theme.palette.text.primary,
  fontSize: '3rem',
  fontWeight: 600,

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: '2rem'
  }
}))

export const Description = styled(Typography)(({ theme }) => ({
  width: '100%',

  marginTop: theme.spacing(1.5),

  color: theme.palette.text.secondary,
  fontSize: '1.2rem',
  fontWeight: 500,

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: '1rem'
  }
}))
