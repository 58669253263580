import React from 'react'
import { useTransition } from 'react-spring'

// * Components
import { Toast } from './Toast'

// * Styled Components
import { Container } from './toast-container.styles'

// * Contexts
import { ToastMessage } from 'contexts'

interface ToastProps {
  messages: ToastMessage[]
}

export const ToastContainer: React.FC<ToastProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(messages, {
    enter: { right: '0%', opacity: 1 },
    from: { right: '-120%', opacity: 0 },
    leave: { right: '-120%', opacity: 0 }
  })

  return (
    <Container>
      {messagesWithTransitions((styles, item) => (
        <Toast key={item.id} style={styles} message={item} />
      ))}
    </Container>
  )
}
export default ToastContainer
