import React from 'react'
import { createTheme, PaletteMode, Theme, ThemeProvider } from '@mui/material'

import { getDesignTokens } from 'styles/theme.style'

const userColorPreference = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

interface IColorModeContext {
  toggleColorMode: () => void
  mode: PaletteMode
  theme: Theme | undefined
}

export const ColorModeContext = React.createContext<IColorModeContext>({
  toggleColorMode: () => {},
  mode: userColorPreference,
  theme: undefined
})

export const ColorModeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const currentMode = (localStorage.getItem('mode') as PaletteMode) || userColorPreference
  const [mode, setMode] = React.useState<PaletteMode>(currentMode)

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
      mode,
      theme
    }),
    [mode, theme]
  )

  React.useEffect(() => {
    localStorage.setItem('mode', mode)
  }, [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export const useColorMode = () => React.useContext(ColorModeContext)
