import React, { createContext, useCallback, useState, useContext } from 'react'
import api from '../services/api'

interface AuthState {
  token: string
  email: string
}

interface SignInCredentials {
  email: string
  password: string
}

interface AuthContextData {
  user: AuthState
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@boilerplate:token')
    const email = localStorage.getItem('@boilerplate:email')

    if (token && email) {
      api.defaults.headers.authorization = `Bearer ${token}`

      return { token, email: JSON.parse(email) }
    }

    return {} as AuthState
  })

  const signOut = useCallback(() => {
    localStorage.removeItem('@boilerplate:token')
    localStorage.removeItem('@boilerplate:email')

    setData({} as AuthState)
  }, [])

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    // const response = await api.post('user', {
    //   email,
    //   password
    // })

    // const { token } = response.data

    localStorage.setItem('@boilerplate:token', 'sakdjasd8o-32424kod-312das')
    localStorage.setItem('@boilerplate:email', JSON.stringify(email))

    api.defaults.headers.authorization = `Bearer ${'sakdjasd8o-32424kod-312das'}`

    setData({ token: 'sakdjasd8o-32424kod-312das', email })
  }, [])

  return (
    <AuthContext.Provider value={{ user: data, signIn, signOut }}>{children}</AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  return context
}

export { AuthProvider, useAuth }
