import React from 'react'
import {
  Checkbox as MUICheckbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  InputLabel,
  styled,
  FormHelperText
} from '@mui/material'
import { Controller } from 'react-hook-form'

type InputTextProps = CheckboxProps & {
  label?: string
  id?: string
  error?: any
}

type InputProps = Partial<InputTextProps> & {
  control: any
  register: any
  name: string
}

const CustomCheckboxField = React.forwardRef<HTMLInputElement, InputTextProps>(({ error, ...props }, ref) => {
  const { label, required, checked: isChecked } = props
  const [checkedItemId, setCheckedItemId] = React.useState(isChecked)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItemId(event.target.checked)
  }

  return (
    <FormControl
        sx={{ m: 1, width: `calc(50% - 16px)` }}
        required={required}
        error={!!error}
    >
      <FormControlLabel
        label={
          <InputLabel sx={{ mt: '-.3em', ml: 1 }} shrink={false}>
            {label}
          </InputLabel>
        }
        disableTypography
        control={
            <MUICheckbox
                required={required}
                onChange={handleChange}
                checked={checkedItemId}
                name={label}
                inputRef={ref}
            />
        }
      />
      {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  )
})

const CustomInput = ({ name, control, register, ...props }: InputProps) => {
  if (!name || !control || !register) {
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <CustomCheckboxField {...props} {...field} {...register(name)} />}
    />
  )
}

export const Checkbox = styled(CustomInput)({
  '.Mui-focused': {
    color: 'yellow'
  },
  '.Mui-disabled': {
    color: 'blue !important'
  },
  '.MuiFormLabel-asterisk': {
    color: 'red !important'
  }
})
