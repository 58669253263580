import React from 'react'

// * Components
import { Checkbox, TableCell } from '@mui/material'
import { format } from 'date-fns'

type ITableItemProps = {
  columns: any[]
  row: any
}

const isValidDate = (dateString: any) => {
  if (!dateString) {
    return false
  }
  dateString = dateString?.toString()
  var regEx = /^\d{4}-\d{2}-\d{2}$/
  if (!dateString.match(regEx)) return false // Invalid format
  var d = new Date(dateString)
  var dNum = d.getTime()
  if (!dNum && dNum !== 0) return false // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString
}


export const TableItem: React.FC<ITableItemProps> = ({ columns, row }) => {
  return (
    <>
      {columns.map(({ name: columnName }) => {
        const rowData = row[columnName]

        // @ts-ignore
        const isDate = isValidDate(rowData)

        if (typeof rowData === 'boolean') {
          return (
            <TableCell key={columnName}>
              <Checkbox size="small" disabled checked={rowData} />
            </TableCell>
          )
        }

        return (
          <TableCell key={columnName}>
            {typeof row[columnName] === 'object'
              ? ''
              : isDate
              ? String(format(new Date(rowData), 'dd/MM/yyyy'))
              : rowData}
          </TableCell>
        )
      })}
    </>
  )
}
