import React from 'react'
import { useNavigate } from 'react-router-dom'

// * Components
import { Button } from 'components'

// * Icons
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai'

// * Styled Components
import { ContainerActions, InputContainer, SearchInput } from './header-actions.styles'

interface IHeaderActionsProps {
  filterText: string
  setFilterText: (value: string) => void
}

export const HeaderActions: React.FC<IHeaderActionsProps> = ({ filterText, setFilterText }) => {
  const navigate = useNavigate()

  const onClickAddButton = () => {
    navigate('new')
  }

  return (
    <ContainerActions sx={{ padding: 2 }}>
      <Button
        variant="gradient"
        sx={{
          borderRadius: '500px',
          fontSize: { sm: '0.8rem', md: '1rem' },
          fontWeight: 'bold',
          paddingX: { sm: 2, md: 2.5 },
          paddingY: { sm: 1, md: 1.5 },
          textTransform: 'capitalize'
        }}
        onClick={onClickAddButton}
      >
        Adicionar
      </Button>

      <InputContainer>
        <SearchInput
          placeholder="Filtrar tabela"
          startAdornment={<SearchIcon size={30} />}
          value={filterText}
          onChange={event => setFilterText(event.target.value)}
        />
      </InputContainer>
    </ContainerActions>
  )
}
