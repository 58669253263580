import React from 'react'
import { Switch as MUISwitch, FormControlLabel, FormControlLabelProps } from '@mui/material'
import { Checkbox } from 'components/Input'

interface SwitchProps extends React.ButtonHTMLAttributes<HTMLInputElement> {
  checked?: boolean
  onClick?: () => void
  disabled?: boolean
  label?: string
  name: string
  /**
   * The position of the label.
   * @default: "end"
   * */
  labelPosition?: FormControlLabelProps['labelPlacement']
  required?: boolean
  register: any,
  control: any,
  error: any,
}

export function Switch({
  checked: isChecked = false,
  label = '',
  disabled = false,
  required,
  style,
  labelPosition = 'end',
  onClick = () => {},
  register,
  control,
  error,
  name,
}: SwitchProps): JSX.Element | null {

  // @see: https://uxplanet.org/checkbox-vs-toggle-switch-7fc6e83f10b8

  if(!!required){
    return (
      <Checkbox
        checked={isChecked}
        label={label}
        disabled={disabled}
        required={true}
        style={style}
        onClick = {onClick}
        control={control}
        register={register}
        error={error}
        name={name}
      />
    )
  }

  return (
    <FormControlLabel
      sx={{ m: 1, width: `calc(50% - 16px)` }}
      label={label}
      style={style}
      labelPlacement={labelPosition}
      control={
        <MUISwitch onClick={onClick} disabled={disabled} defaultChecked={isChecked} />
      }
    />
  )
}
