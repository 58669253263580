import React from 'react'

import { GlobalStyles as MUIGlobalStyles } from '@mui/material'
import { useColorMode } from 'contexts'

export const GlobalStyles: React.FC = () => {
  const { theme } = useColorMode()

  return (
    <MUIGlobalStyles
      styles={{
        '*': {
          fontFamily: theme?.typography.fontFamily,
          transition: 'background-color 1s ease!important'
        },
        body: {
          margin: 0,
          padding: 0,
          backgroundColor: theme?.palette?.background?.default
        },
        '#root': {
          display: 'flex'
        },
        '.MuiFormLabel-asterisk': {
          color: 'red!important'
        },
        '@keyframes spin': {
          from: {
            transform: 'rotate(0deg)'
          },
          to: {
            transform: 'rotate(360deg)'
          }
        }
      }}
    />
  )
}
