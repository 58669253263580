import { styled } from '@mui/material'

// * Components
import { Link as LinkRouter } from 'react-router-dom'

export const Link = styled(LinkRouter)(({ theme }) => ({
  width: '100%',
  textDecoration: 'none',
  color: theme.palette.text.primary
}))
