import { useEffect, useState, useContext } from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'

// * Context
import { DataBaseContext, useAuth } from 'contexts'

// * Components
import { Edit, List, Login, New, NotFound } from 'pages'
import { Menu } from 'core/menu'

// * Types
import { Table } from 'types/table.type'

interface IProtectedRoutes {
  redirectTo: string
}

const ProtectedRoutes = ({ redirectTo }: IProtectedRoutes) => {
  const { user } = useAuth()
  const token = user.token

  return token ? <Outlet /> : <Navigate to={redirectTo} />
}

const OnlyNotLoggedRoute = ({ redirectTo }: IProtectedRoutes) => {
  const { user } = useAuth()
  const token = user.token

  return token ? <Navigate to={redirectTo} /> : <Login />
}

export const MainRoutes: React.FC = () => {
  const [tablesData, setTablesData] = useState<Table[]>([])
  const [routes, setRoutes] = useState<string[]>([])
  const { raw, filteredAbstractTables } = useContext(DataBaseContext)

  useEffect(() => {
    const getRoutes = async () => {
      const menuRoutes = Object.keys(filteredAbstractTables)

      setTablesData(raw)
      setRoutes(menuRoutes)
    }

    getRoutes()
  }, [filteredAbstractTables, raw])

  return (
    <>
      <Menu routes={routes} />

      <Routes>
        <Route path="/signin" element={<OnlyNotLoggedRoute redirectTo="/" />} />

        <Route path="/" element={<ProtectedRoutes redirectTo="/signin" />} />

        {tablesData.map(tableData => (
          <Route key={tableData.name} element={<ProtectedRoutes redirectTo="/signin" />}>
            <Route path={tableData.name} element={<List data={tableData} />} />
            <Route path={`${tableData.name}/edit/:id`} element={<Edit data={tableData} />} />
            <Route path={`${tableData.name}/new`} element={<New data={tableData} />} />
          </Route>
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}
