import { styled, Box, Typography } from '@mui/material'

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',

  padding: theme.spacing(6.25),

  display: 'flex',
  flexDirection: 'column',

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    padding: `${theme.spacing(10)} ${theme.spacing(2.5)}`
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '3rem',
  fontWeight: 600,

  marginTop: theme.spacing(2),

  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: '2rem'
  }
}))
