import { styled, TextField as MUITextField } from '@mui/material'

export const TextField = styled(MUITextField)(({ theme }) => ({
  width: `calc(50% - 16px)`,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  boxSizing: 'border-box',
  '& input[type=number]': {
    appearance: 'textfield'
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: '100%'
  }
}))
