import { AppBar, styled, Toolbar as MUIToolbar, Typography } from '@mui/material'

export const Bar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  '@media screen and (min-width: 601px)': {
    display: 'none'
  }
}))

export const Toolbar = styled(MUIToolbar)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const Title = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),

  flexGrow: 1,

  color: theme.palette.text.primary,
  fontSize: '1.8rem'
}))
