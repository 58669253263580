import React from 'react'

// * Styled Components
import { Container, IconDump, IconTable, Label } from './tab-item.styles'

// * Enums
import { CurrentPage } from 'pages/crud/components/list/enums/current-page.enum'

interface ITabItemProps {
  label: string
  name: string
  disabled?: number
  currentPage: string
  onClick: () => void
}

interface IIconProps {
  name: string
  size: number
}

const Icon: React.FC<IIconProps> = ({ name, size }) => {
  return name === CurrentPage.ITEMS ? <IconTable size={size} height={size} /> : <IconDump size={size} height={size} />
}

export const TabItem: React.FC<ITabItemProps> = ({ label, name, disabled, currentPage, onClick }) => {
  const isActive = currentPage === name ? 1 : 0

  return (
    <Container active={isActive} disabled={disabled} onClick={Boolean(disabled) ? undefined : onClick}>
      <Icon name={name} size={16} />
      <Label variant="h6">{label}</Label>
    </Container>
  )
}
