import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BackContainer, Container, Description, IconBack, LabelBack, Title } from './not-found.styles'

export const NotFound: React.FC = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/')
  }

  return (
    <Container>
      <BackContainer onClick={handleBack}>
        <IconBack />
        <LabelBack sx={{ marginLeft: 0.5 }}>Voltar para a home</LabelBack>
      </BackContainer>

      <Title variant="h3" sx={{ marginTop: 2 }}>
        Oops.. Página não encontrada!
      </Title>

      <Description variant="subtitle1">
        A página que você tentou acessar não foi encontrada, verifique a URL.
      </Description>
    </Container>
  )
}
