import { Box, styled } from '@mui/material'

import { BsSunFill, BsMoonFill } from 'react-icons/bs'

export const Container = styled(Box)<{ size: number }>(({ theme, size }) => ({
  width: size,
  height: size
}))

export const IconLight = styled(BsSunFill)(({ theme }) => ({
  fill: theme.palette.text.primary
}))

export const IconDark = styled(BsMoonFill)(({ theme }) => ({
  fill: theme.palette.text.primary
}))
