import React from 'react'

// * Components
import { List } from '@mui/material'
import { MenuItem } from './components/menu-item'
import { Drawer } from './components/drawer/drawer.component'
import { Logout } from 'components/Logout/logout.component'

// * Styled Components
import { ContainerWithShadow, LogoutContainer, MenuContainer, TitleContainer, Title } from './menu.styles'

// * Contexts
import { useAuth } from 'contexts/auth.context'

interface IMenuProps {
  routes: string[]
}

export const Menu: React.FC<IMenuProps> = ({ routes }) => {
  const { user } = useAuth()

  const userIsLogged = !!user.token

  return (
    <>
      {userIsLogged ? (
        <>
          <ContainerWithShadow elevation={4}>
            <TitleContainer sx={{ paddingTop: 3.75 }}>
              <Title variant="h2">FireDev</Title>
            </TitleContainer>

            <MenuContainer sx={{ marginY: 4.375 }}>
              <List disablePadding>
                {routes.map((route, index) => (
                  <MenuItem key={index} route={route} />
                ))}
              </List>
            </MenuContainer>

            <LogoutContainer sx={{ paddingBottom: 3.75 }}>
              <Logout />
            </LogoutContainer>
          </ContainerWithShadow>

          <Drawer routes={routes} userIsLogged={userIsLogged} />
        </>
      ) : (
        <Drawer routes={routes} userIsLogged={userIsLogged} />
      )}
    </>
  )
}
