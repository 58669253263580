import React, { useEffect } from 'react'

// * Components
import { Typography } from '@mui/material'

// * Styled Components
import { Title, ToastContainer } from './toast.styles'

// * Icons
import { RiCloseFill as IconClose } from 'react-icons/ri'

// * Contexts
import { ToastMessage, useToast } from 'contexts/toast.context'

interface ToastProps {
  message: ToastMessage
  style: object
}

export const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [removeToast, message.id])

  return (
    <ToastContainer key={message.id} type={message.type} style={style}>
      <div>
        <Title variant="h5">{message.title}</Title>
        <Typography variant="body2">{message.description}</Typography>
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <IconClose color="#000" />
      </button>
    </ToastContainer>
  )
}

export default Toast
